import React, { useState,useEffect } from 'react';
import { TextField, Button, Container, Typography, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios, { AxiosError } from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const apiUrl = process.env.REACT_APP_API_URL;


const LoginForm: React.FC = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleLogin = async () => {
    try {
      setLoading(true);

      const response = await axios.post(
        `${apiUrl}/auth/login-customer?language=en`,
        // 'https://localhost:32772/api/auth/login-customer?language=en',
        {
          username: username,
          password: password,
        }
      );

      if (response.status >= 200 && response.status < 300) {
        const responseData = response.data;
        const authToken = responseData.token;

        // Store the token in localStorage
        localStorage.setItem('authToken', authToken);
        navigate('/scheduling');
      } else {
        throw new Error('Đăng nhập không thành công');
      }
    } catch (error) {
      console.error('Lỗi đăng nhập:');

      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError;
        // Handle Axios-specific error properties here if needed
      }

      toast.error('Đăng nhập không thành công', {
        // Your toast configuration
      });
    } finally {
      setLoading(false);
    }
  };

  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: '100vh',
      marginBottom: '20px',
    },
    form: {
      width: '100%',
      maxWidth: '400px',
      marginBottom: '20px',
    },
    formElement: {
      marginBottom: '15px',
    },
    button: {
      marginTop: '15px',
      position: 'relative', // Needed for absolute positioning of CircularProgress
    },
    loadingSpinner: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: '-12px', // Half of CircularProgress size
      marginLeft: '-12px', // Half of CircularProgress size
    },
    toastContainer: {
      marginTop: '20px',
    },
    centeredText: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: '20px',
    },
  };

  return (
    <Container
      component="main"
      maxWidth="xs"
      style={styles.container as React.CSSProperties}
    >
      <div style={styles.form as React.CSSProperties}>
        <div style={styles.centeredText as React.CSSProperties}>
          <Typography component="h1" variant="h5">
            MẮM FROM HANOI
          </Typography>
        </div>
        <form>
          <TextField
            label="Tên đăng nhập"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            fullWidth
            style={styles.formElement as React.CSSProperties}
          />
          <TextField
            label="Mật khẩu"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            fullWidth
            style={styles.formElement as React.CSSProperties}
          />
          <Button
            type="button"
            fullWidth
            variant="contained"
            color="primary"
            onClick={handleLogin}
            style={styles.button as React.CSSProperties}
            disabled={loading}
          >
            {loading ? 'Đang đăng nhập...' : 'Đăng nhập'}
            {loading && (
              <CircularProgress
                size={24}
                style={styles.loadingSpinner as React.CSSProperties}
              />
            )}
          </Button>
        </form>
      </div>
      <div style={styles.toastContainer as React.CSSProperties}>
        <ToastContainer />
      </div>
    </Container>
  );
};

export default LoginForm;
