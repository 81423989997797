import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../Styles/styles.css';

interface HeaderProps {
  employeeName: string;
}

const Header: React.FC<HeaderProps> = ({ employeeName }) => {
  const navigate = useNavigate();

  const handleLogout = () => {
    // Remove the authentication token from local storage
    localStorage.removeItem('authToken');
    
    // Navigate to the desired route (e.g., login page)
    navigate('/');
  };

  return (
    <div className="header">
      <div className="header-content">
        <h1>{employeeName}</h1>
        <div className="nav-buttons">
          <Link to="/scheduling">Ghi Giờ Làm</Link>
          <Link to="/view-work-hours">Xem Giờ Làm</Link>
          <button onClick={handleLogout}>Đăng Xuất</button>
        </div>
      </div>
    </div>
  );
};

export default Header;
