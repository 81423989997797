// src/components/SchedulingPage.tsx
import React, { useEffect,useState } from 'react';
import { isAuthenticated } from '../components/authService';
import { useNavigate } from 'react-router-dom';
import { Select, MenuItem, Button, Container, Typography, TextField, SelectChangeEvent } from '@mui/material';
import axios from 'axios';
import Header from '../components/header';
import Footer from '../components/Footer';
import User from '../interfaces/Users';
import { useUser } from '../components/UserContext';
const apiUrl = process.env.REACT_APP_API_URL;


const SchedulingPage: React.FC = () => {
  const navigate = useNavigate();
  const { currentUser, setCurrentUser } = useUser();
  useEffect(() => {
    // Use the useEffect hook to perform actions after the component is mounted
    const fetchCurrentUser = async () => {
      try {
        // Make the API request to get the current user information
        const token = localStorage.getItem('authToken');
        const response = await axios.get(`${apiUrl}/users/getcurentuser`, {
        headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        // Assuming the response data contains user information
        const userData = response.data;

        // Store the user information in the local state
        setCurrentUser(userData);
      } catch (error) {
        console.error('Error fetching current user:');
      }
    };

    // Call the fetchCurrentUser function when the component mounts
    fetchCurrentUser();
    // Check if the user is authenticated, if not, redirect to the login page
    if (!isAuthenticated()) {
      // Redirect to the login page
      navigate('/');
    }
    

  }, [navigate]); // Include navigate in the dependency array to avoid linting warning
  const [shiftType, setShiftType] = useState('');
    const [selectedDate, setSelectedDate] = useState('');
    const [startTimeDate, setStartTime] = useState('');
    const [endTimeDate, setEndTime] = useState('');

    const handleShiftTypeChange = (event: SelectChangeEvent<string>) => {
    setShiftType(event.target.value as string);
  };

  const handleConfirm = async () => {
    const confirmed = window.confirm('Bạn có chắc chắn muốn lưu ca làm việc không?');
      if (!confirmed) {
        return; // Nếu không chắc chắn, dừng việc tiếp tục xử lý
      }
    try {
      const token = localStorage.getItem('authToken');
 

      const requestData = {
        shiftType: shiftType,
        workDate: selectedDate,
        startTime: startTimeDate,
        endTime: endTimeDate,
      };

      // Gọi API POST để lưu thông tin ca làm việc
      const response = await axios.post(`${apiUrl}/WorkShifts/add`, requestData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      // Kiểm tra xem API có trả về thành công hay không
      if (response.status === 201) {
        alert('Ca làm việc đã được lưu thành công!');
        setShiftType('');
        setSelectedDate('');
        setStartTime('');
        setEndTime('');
      } else {
        alert('Có lỗi xảy ra khi lưu ca làm việc.');
      }
    } catch (error) {
      console.error('Error while saving work shift:', error);
    }
  };
  
  // Assuming you have styles defined in your component or a separate stylesheet
interface Styles {
    container: React.CSSProperties;
    form: React.CSSProperties;
    formElement: React.CSSProperties;
    button: React.CSSProperties;
  }
  
  const styles: Styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: '100vh',
    },
    form: {
      width: '100%',
      maxWidth: '400px',
    },
    formElement: {
      marginBottom: '15px',
    },
    button: {
      marginTop: '15px',
    },
  };
  
  // Your component
  return (
    <Container component="main" maxWidth="xs" style={styles.container}>
      {currentUser && <Header employeeName={currentUser.fullName} />}
      <div style={styles.form}>
        <Typography component="h1" variant="h5">
          Chọn Ca Làm
        </Typography>
        <Select
          label="Ca Làm"
          value={shiftType}
          onChange={handleShiftTypeChange}
          fullWidth
          style={styles.formElement}
        >
          <MenuItem value="morning">Ca Sáng</MenuItem>
          <MenuItem value="evening">Ca Tối</MenuItem>
        </Select>
        {shiftType && (
          <>
            <TextField
              label="Ngày Tháng"
              type="date"
              value={selectedDate}
              onChange={(e) => setSelectedDate(e.target.value)}
              InputLabelProps={{ shrink: true }}
              fullWidth
              style={styles.formElement}
            />
            <TextField
              label="Giờ Vào"
              type="time"
              value={startTimeDate}
              onChange={(e) => setStartTime(e.target.value)}
              InputLabelProps={{ shrink: true }}
              fullWidth
              style={styles.formElement}
            />
            <TextField
              label="Giờ Ra"
              type="time"
              value={endTimeDate}
              onChange={(e) => setEndTime(e.target.value)}
              InputLabelProps={{ shrink: true }}
              fullWidth
              style={styles.formElement}
            />
            <Button
              type="button"
              fullWidth
              variant="contained"
              color="primary"
              onClick={handleConfirm}
              style={styles.button}
            >
              Xác Nhận
            </Button>
          </>
        )}
      </div>
      <Footer></Footer>
    </Container>
  );
  

  
};

export default SchedulingPage;
