// src/components/Footer.tsx
import React from 'react';

const Footer: React.FC = () => {
  return (
    <div style={{ textAlign: 'center', marginTop: '20px', marginBottom: '20px', padding: '10px', backgroundColor: '#f0f0f0' }}>
      Powered by NghiaLe
    </div>
  );
};

export default Footer;
