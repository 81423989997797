// src/components/WorkShiftsByMonthPage.tsx
import React, { useState, useEffect } from 'react';
import { useUser } from '../components/UserContext';
import { Container, Typography, Select, MenuItem, Button, Table, TableHead, TableRow, TableCell, TableBody, TableContainer } from '@mui/material';
import axios from 'axios';
import { isAuthenticated } from '../components/authService';
import { useNavigate } from 'react-router-dom';
import Header from '../components/header';
import Footer from '../components/Footer';

const apiUrl = process.env.REACT_APP_API_URL;

const ViewWorkHours: React.FC = () => {
  const navigate = useNavigate();
  const { currentUser } = useUser();
  const [selectedMonth, setSelectedMonth] = useState<number>(1);
  const [selectedYear, setSelectedYear] = useState<number>(2024);
  const [workShifts, setWorkShifts] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    // Retrieve the state from local storage
    const storedMonth = localStorage.getItem('selectedMonth');
    const storedYear = localStorage.getItem('selectedYear');

    if (storedMonth) {
      setSelectedMonth(Number(storedMonth));
    }

    if (storedYear) {
      setSelectedYear(Number(storedYear));
    }

    if (!isAuthenticated()) {
        navigate('/');
      }
  }, []);

  

  const calculateTotalHours = (startTime: string, endTime: string): number => {
    const startDate = new Date(`2000-01-01 ${startTime}`);
    const endDate = new Date(`2000-01-01 ${endTime}`);
    const timeDiff = endDate.getTime() - startDate.getTime();
    const totalHours = timeDiff / (1000 * 60 * 60);
    return parseFloat(totalHours.toFixed(2));
  };

  const calculateTotalSum = () => {
    return workShifts.reduce((sum, workShift) => {
      const totalHours = calculateTotalHours(workShift.startTime, workShift.endTime);
      return isNaN(totalHours) ? sum : sum + totalHours;
    }, 0);
  };

  const handleConfirm = async () => {
    try {
      const token = localStorage.getItem('authToken');
      setLoading(true);

      const response = await axios.get(`${apiUrl}/WorkShifts/ByMonth`, {
        params: {
          month: selectedMonth,
          year: selectedYear,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        setWorkShifts(response.data);
      } else {
        console.error('Error fetching work shifts:', response.data);
      }
    } catch (error) {
      console.error('Error while fetching work shifts:', error);
    } finally {
      setLoading(false);
    }
  };

  // Save the state to local storage whenever it changes
  useEffect(() => {
    localStorage.setItem('selectedMonth', selectedMonth.toString());
    localStorage.setItem('selectedYear', selectedYear.toString());
  }, [selectedMonth, selectedYear]);

  return (
    <Container component="main" maxWidth="xs">
      {currentUser && <Header employeeName={currentUser.fullName} />}
      {currentUser && (
        <>
          <Typography component="h1" variant="h5" style={{ marginBottom: '20px' }}>
            Chọn Tháng và Năm
          </Typography>
          <div>
            <Select
              label="Tháng"
              value={selectedMonth}
              onChange={(e) => setSelectedMonth(Number(e.target.value))}
              fullWidth
              style={{ marginBottom: '10px' }}
            >
              {Array.from({ length: 12 }, (_, index) => index + 1).map((month) => (
                <MenuItem key={month} value={month}>
                  {month}
                </MenuItem>
              ))}
            </Select>
            <Select
              label="Năm"
              value={selectedYear}
              onChange={(e) => setSelectedYear(Number(e.target.value))}
              fullWidth
              style={{ marginBottom: '20px' }}
            >
              {Array.from({ length: 10 }, (_, index) => 2022 + index).map((year) => (
                <MenuItem key={year} value={year}>
                  {year}
                </MenuItem>
              ))}
            </Select>
            <Button
              type="button"
              fullWidth
              variant="contained"
              color="primary"
              onClick={handleConfirm}
              disabled={loading}
            >
              {loading ? 'Đang Xử Lý...' : 'Xác Nhận'}
            </Button>
          </div>
          {workShifts.length > 0 && (
            <div style={{ marginTop: '20px', overflowX: 'auto' }}>
              <Typography component="h2" variant="h6" style={{ marginBottom: '10px' }}>
                Danh sách ca làm việc
              </Typography>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>STT</TableCell>
                      <TableCell>Ngày làm</TableCell>
                      <TableCell>Giờ vào</TableCell>
                      <TableCell>Giờ ra</TableCell>
                      <TableCell>Tổng giờ làm</TableCell>
</TableRow>
</TableHead>
<TableBody>
{workShifts.map((workShift, index) => (
<TableRow key={index}>
<TableCell>{index + 1}</TableCell>
<TableCell>{new Date(workShift.workDate).toLocaleDateString()}</TableCell>
<TableCell>{workShift.startTime.trim()}</TableCell>
<TableCell>{workShift.endTime.trim()}</TableCell>
<TableCell>
{calculateTotalHours(workShift.startTime, workShift.endTime)} giờ
</TableCell>
</TableRow>
))}
{/* Add a row for total hours */}
<TableRow>
<TableCell colSpan={3}></TableCell>
<TableCell style={{ textAlign: 'right' }}>Tổng cộng</TableCell>
<TableCell>{calculateTotalSum()} giờ</TableCell>
</TableRow>
</TableBody>
</Table>
</TableContainer>
</div>
)}
</>
)}
<Footer></Footer>
</Container>
);
};

export default ViewWorkHours;
