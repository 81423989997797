// src/authService.ts
export const isAuthenticated = () => {
    return localStorage.getItem('authToken') !== null;
  };
  
  export const login = (token: string) => {
    localStorage.setItem('authToken', token);
  };
  
  export const logout = () => {
    localStorage.removeItem('authToken');
  };
  